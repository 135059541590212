import moment from "moment";
import { useTranslation } from "react-i18next";

export const duration = (endAt?: Date, startAt?: Date) => {
  const { t } = useTranslation();

  const duration = moment.duration(
    (endAt?.getTime() || 0) - (startAt?.getTime() || 0)
  );

  const components = [];
  if (duration.days() > 0)
    components.push(
      t("{{count}} days", { count: Math.round(duration.asDays()) })
    );
  if (duration.hours() > 0)
    components.push(t("{{count}} hours", { count: duration.hours() }));
  if (duration.minutes() > 0)
    components.push(t("{{count}} mins", { count: duration.minutes() }));
  return components.join(`${t(",")} `);
};
