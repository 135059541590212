import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { currencyToDisplayString, slowClock } from "../../../helpers";
import { Booking, getBookingCost } from "../../../stores";
import { LicensePlate, ListItem, Margins, Button } from "../../../storybook";
import { duration } from "../utils";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    marginVertical: Margins.small,
  },
  col: {
    flex: 1,
  },
});

interface Props {
  booking: Booking;
  checkout?: boolean;
  pay: () => void;
  close: () => void;
  loading?: boolean;
}

const BookingDirectConfirm = (props: Props) => {
  const { booking, pay, close, loading } = props;
  const { vehicle, displayStartDate, displayStartTime, startAt, spot } =
    booking;
  const { rates } = spot || {};

  const { t } = useTranslation();
  const endAt = booking.endAt ? booking.endAt : slowClock.date;

  const displayEndDate = moment(endAt).format("LL");
  const displayEndTime = moment(endAt).format("LT");
  const cost =
    rates && getBookingCost(rates, startAt, endAt, spot?.gracePeriod);

  const isFree = cost?.amount === 0;

  return (
    <>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("License plate")}
            description={
              <LicensePlate
                country={vehicle?.country}
                code={vehicle?.licensePlate}
              />
            }
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("Found vehicle")}
            description={vehicle?.description || t("Unknown vehicle")}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("Start time")}
            description={`${displayStartDate}\n${displayStartTime}`}
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("End time")}
            description={`${displayEndDate}\n${displayEndTime}`}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("Total duration")}
            description={duration(endAt, startAt)}
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("Outstanding amount")}
            description={`${currencyToDisplayString(
              cost?.currency || "eur",
              cost?.amount || 0
            )}`}
          />
        </View>
      </View>
      {!isFree ? (
        <Button
          title={t("Pay now")}
          right="arrowright"
          type="green"
          style={{ width: "100%", marginVertical: Margins.small }}
          onPress={pay}
        />
      ) : (
        <Button
          title={t("End booking")}
          right="arrowright"
          type="green"
          style={{ width: "100%", marginVertical: Margins.small }}
          onPress={close}
          loading={loading}
        />
      )}
    </>
  );
};

export default observer(BookingDirectConfirm);
