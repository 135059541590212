import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { Time } from "@storybook/component/input/timeinput";
import { observer } from "mobx-react";
import React, { useState, ReactNode, useMemo, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import Collapsible from "react-native-collapsible";

import useLastScannedLicensePlate from "./hooks/useLastScannedLicensePlate";
import useSpotAvailability from "./hooks/useSpotAvailability";
import useVehicleUpdater from "./hooks/useVehicleUpdater";
import { Page, SpotClosing, VehicleForm } from "../../components";
import { duration } from "../../components/booking/utils";
import {
  currencyToDisplayString,
  reportError,
  translatedError,
} from "../../helpers";
import { PublicStackParamList } from "../../routes/public";
import {
  Bookings,
  getBookingCost,
  Spot,
  useStore,
  withAuthentication,
} from "../../stores";
import { sanitizeLicense } from "../../stores/parking/vehicle/vehicles";
import {
  Button,
  Margins,
  Title,
  Text,
  Colors,
  WeekCalenderNew,
  Icon,
  TimeInput,
  ListItem,
  Radius,
} from "../../storybook";

type StartNavigationProp = StackNavigationProp<
  PublicStackParamList,
  "PublicStart"
>;
type StartRouteProp = RouteProp<PublicStackParamList, "PublicStart">;

interface Props {
  navigation: StartNavigationProp;
  route: StartRouteProp;
}

const ScannedStart = (props: Props) => {
  const { route, navigation } = props;
  const spotId = route.params?.spotId;
  const { t } = useTranslation();
  const store = useStore();
  const { auth, i18n } = store;

  const spot = useMemo(() => new Spot(`spots/${spotId}`, {}, store), [spotId]);

  const { vehicle, updateVehicle, setForceUpdate } =
    useLastScannedLicensePlate(spotId);
  const { availability, closingAvailability } = useSpotAvailability(spot);
  const { getVehicle } = useVehicleUpdater();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode>();
  const [vehicleDescription, setVehicleDescription] = useState<
    string | undefined
  >("");

  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [fourthStep, setFourthStep] = useState(false);

  const [toggleFirstStep, setToggleFirstStep] = useState(false);
  const [toggleSecondStep, setToggleSecondStep] = useState(false);

  const [selectedMethod, setSelectedMethod] = useState("");

  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const { hourly } = spot.rates;

  const textToTime = (text: string) => {
    const [hours, minutes] = text.split(":");
    return {
      hours: (parseInt(hours, 10) || 0) % 24,
      minutes: (parseInt(minutes, 10) || 0) % 60,
    } as Time;
  };

  useEffect(() => {
    const newSelectedDate = selectedEndDate;

    newSelectedDate.setHours(time?.hours);
    newSelectedDate.setMinutes(time?.minutes);

    setSelectedEndDate(newSelectedDate);
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(undefined), 3000);
    }
  }, [error]);

  const create = async () => {
    if (!vehicle || !vehicle.code || !vehicle.country) return;
    setLoading(true);

    try {
      if (auth.user?.id) {
        const bookings = new Bookings(
          { userId: auth.user.id, payment: "failed" },
          store
        );
        const failed = await bookings.fetch();
        if (failed.docs.length > 0) {
          throw new Error("You have open payments");
        }
      }

      const licenseRef = sanitizeLicense(vehicle.code);
      const bookings = new Bookings(
        { spotId, "vehicle.licenseRef": licenseRef },
        store
      );
      await bookings.fetch();

      let newBooking = bookings.hasDocs ? bookings.docs[0] : undefined;

      if (!newBooking) {
        const userId = auth.user?.id;
        if (!userId) return;
        const vehicleDoc = await getVehicle(vehicle);
        if (!vehicleDoc?.id) throw new Error("Failed to create vehicle");

        let endAt;
        if (availability && !availability.open) {
          endAt = availability.endAt;
        }

        const response = await bookings.request({
          anonymous: true,
          startAt: new Date(),
          endAt: selectedMethod === "direct" ? endAt : selectedEndDate,
          spot,
          vehicleIds: [vehicleDoc.id],
          kiosk: selectedMethod === "booking",
        });
        newBooking = response.booking;
        console.info(`New booking: ${newBooking?.id}`);
      } else {
        console.info(`Active booking: ${newBooking.id}`);
      }

      await newBooking?.openGate("entrance");
      const bookingId = newBooking?.id || "";

      if (Platform.OS === "web") {
        window.location.href = `/public/started/${bookingId}?goBack=true`;
      } else {
        navigation.navigate("PublicStarted", { bookingId, goBack: true });
      }
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      reportError(err as Error, { name: "public/scanned-start", type: "web" });
      setError(translatedError(message));
    } finally {
      setLoading(false);
    }
  };

  const updateLanguage = (language: string) => {
    const dataForLanguage = (lang: string) => {
      switch (lang.toLowerCase()) {
        case "nl":
          return "nl-NL";
        case "fr":
          return "fr";
        case "de":
          return "de";
        default:
          return "en-GB";
      }
    };

    i18n.changeLocale(dataForLanguage(language));
  };

  const displayHourlyRates = currencyToDisplayString(
    hourly.currency,
    hourly.value,
    true
  );
  const displayDailyRates = currencyToDisplayString(
    hourly.currency,
    hourly.daily ?? 0,
    true
  );
  const displayMinumumRates = currencyToDisplayString(
    hourly.currency,
    hourly.min ?? 0.5,
    true
  );

  const isClosed =
    closingAvailability &&
    closingAvailability.startAt.getTime() ===
      closingAvailability.endAt.getTime();

  const licensePlateValid = (vehicle?.code?.length || 0) > 0;

  const now = new Date();
  const formattedTime = now.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const [time, setTime] = useState(textToTime(formattedTime));

  const cost = getBookingCost(spot.rates, new Date(), selectedEndDate);

  const parkingDuration = duration(selectedEndDate, now);

  return (
    <Page spot={spot} tabletSizeScreen noHeader>
      <View style={{ flex: 1, width: 768, paddingHorizontal: 20 }}>
        <Title level={2} style={[{ textAlign: "center" }]}>
          <Trans>Welcome to</Trans>
        </Title>
        <View style={styles.welcomeContent}>
          <Icon
            name="parkingSquare"
            size={180}
            fill={Colors.sky}
            color={Colors.cream}
          />
          <Title level={1}>
            <Trans>Parking</Trans> {spot.name}
          </Title>
        </View>

        <View style={styles.content}>
          <TouchableOpacity
            style={styles.paymentRow}
            onPress={() => setToggleFirstStep(!toggleFirstStep)}
          >
            <Title level={3} style={styles.title}>
              <Trans>1. Scanned license plate</Trans>
            </Title>
            {secondStep && (
              <VehicleForm
                value={vehicle}
                onChange={(vehicle) => {
                  updateVehicle(vehicle);
                  setVehicleDescription(vehicle.vehicle);
                  // update language
                  if (vehicle.country) updateLanguage(vehicle.country);
                }}
                huge
                noInput
              />
            )}
            <Icon name="down" />
          </TouchableOpacity>
          <Collapsible collapsed={toggleFirstStep}>
            <View style={styles.paymentRow}>
              <Title style={{ marginBottom: Margins.tiny }}>
                <Trans>Scanned license plate</Trans>
              </Title>
              <Title
                style={{
                  marginBottom: Margins.tiny,
                  color: Colors.mediumOrange,
                }}
              >
                <Trans>Press a character to edit</Trans>
              </Title>
            </View>
            <View style={[styles.row, { justifyContent: "center" }]}>
              <VehicleForm
                value={vehicle}
                onChange={(vehicle) => {
                  updateVehicle(vehicle);
                  setVehicleDescription(vehicle.vehicle);
                  // update language
                  if (vehicle.country) updateLanguage(vehicle.country);
                }}
                huge
              />
            </View>
            <View style={styles.updateButtonsContainer}>
              <Button
                type="outline"
                title={t("Scan")}
                centre="video"
                size="small"
                onPress={() => setForceUpdate(true)}
              />
              {licensePlateValid && (
                <Button
                  type="transparentOrange"
                  title={t("Reset")}
                  centre="rotateCcw"
                  size="small"
                  onPress={() => updateVehicle({ code: "", country: "NL" })}
                />
              )}
            </View>

            {!secondStep && licensePlateValid && (
              <Button
                title={t("Next step")}
                centre="arrowDownCircle"
                style={[styles.item, { alignSelf: "center" }]}
                size="large"
                onPress={() => {
                  setToggleFirstStep(!toggleFirstStep);
                  setSecondStep(!secondStep);
                }}
              />
            )}

            <SpotClosing availability={closingAvailability} spot={spot} />
          </Collapsible>
        </View>
        <View
          style={[styles.content, { opacity: !secondStep ? 0.5 : 1 }]}
          pointerEvents={!secondStep ? "none" : "auto"}
        >
          <TouchableOpacity
            style={styles.welcomeContent}
            onPress={() => setToggleSecondStep(!toggleSecondStep)}
          >
            <Title level={3} style={styles.title}>
              <Trans>2. Choose your parking preference</Trans>
            </Title>
            {selectedMethod !== "" && secondStep && (
              <View style={styles.selectedMethodContent}>
                <Icon
                  name={selectedMethod === "booking" ? "calendar" : "clock"}
                  size={20}
                  color={Colors.forest}
                />
                <Title level={4} style={{ lineHeight: 24, flex: 1 }}>
                  <Trans>
                    {selectedMethod === "booking"
                      ? "Multi-entry booking"
                      : "1x In & out"}
                  </Trans>
                </Title>
                <Icon name="down" />
              </View>
            )}
          </TouchableOpacity>
          <Collapsible collapsed={toggleSecondStep}>
            <View
              style={styles.selectedMethod}
              pointerEvents={!secondStep ? "none" : "auto"}
            >
              <TouchableOpacity
                style={[
                  styles.selectedMethodButton,
                  {
                    backgroundColor:
                      selectedMethod === "direct"
                        ? Colors.forest
                        : Colors.cream,
                  },
                ]}
                onPress={() => setSelectedMethod("direct")}
              >
                <View style={styles.selectedMethodButtonContent}>
                  <Icon
                    name="clock"
                    size={60}
                    color={
                      selectedMethod === "direct" ? Colors.lime : Colors.forest
                    }
                  />
                  <Title
                    level={3}
                    style={[
                      { lineHeight: 24, flex: 1 },
                      selectedMethod === "direct" && { color: Colors.lime },
                    ]}
                  >
                    <Trans>1x In & out</Trans>
                  </Title>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.selectedMethodButton,
                  {
                    backgroundColor:
                      selectedMethod === "booking"
                        ? Colors.forest
                        : Colors.cream,
                  },
                ]}
                onPress={() => setSelectedMethod("booking")}
              >
                <View style={styles.selectedMethodButtonContent}>
                  <Icon
                    name="calendar"
                    size={60}
                    color={
                      selectedMethod === "booking" ? Colors.lime : Colors.forest
                    }
                  />
                  <Title
                    level={3}
                    style={[
                      { lineHeight: 24, flex: 1 },
                      selectedMethod === "booking" && { color: Colors.lime },
                    ]}
                  >
                    <Trans>Multi-entry booking</Trans>
                  </Title>
                </View>
                <Text
                  style={selectedMethod === "booking" && { color: Colors.lime }}
                >
                  <Trans>
                    You can enter and exit the garage multiple times during your
                    stay.
                  </Trans>
                </Text>
              </TouchableOpacity>
            </View>
            {!thirdStep && secondStep && selectedMethod !== "" && (
              <Button
                title={t("Next step")}
                centre="arrowDownCircle"
                style={[styles.item, { alignSelf: "center" }]}
                size="large"
                onPress={() => {
                  setToggleSecondStep(!toggleSecondStep);

                  if (selectedMethod === "direct") {
                    setFourthStep(!fourthStep);
                    return;
                  }
                  setThirdStep(!thirdStep);
                }}
                pointerEvents={!secondStep ? "none" : "auto"}
              />
            )}
          </Collapsible>
        </View>

        {selectedMethod === "booking" && (
          <View
            style={[styles.content, { gap: 20, opacity: !thirdStep ? 0.5 : 1 }]}
            pointerEvents={!thirdStep ? "none" : "auto"}
          >
            <Title level={3} style={styles.title}>
              <Trans>3. When are you leaving?</Trans>
            </Title>
            <WeekCalenderNew
              onChange={(date) => {
                const newDate = date;
                newDate.setHours(time?.hours);
                newDate.setMinutes(time?.minutes);
                setSelectedEndDate(newDate);
              }}
              language={vehicle.country}
            />
            <View style={{ alignItems: "center" }}>
              <TimeInput
                value={time}
                style={styles.timeInput}
                onChange={(time) => {
                  if (time) {
                    time && setTime(time);
                    const newSelectedDate = selectedEndDate;

                    newSelectedDate.setHours(time?.hours);
                    newSelectedDate.setMinutes(time?.minutes);

                    setSelectedEndDate(newSelectedDate);
                  }
                }}
                incrementalInput
              />
              {!fourthStep && (
                <Button
                  title={t("Next step")}
                  centre="arrowDownCircle"
                  style={[styles.item, { alignSelf: "center" }]}
                  size="large"
                  onPress={() => {
                    setFourthStep(!fourthStep);
                  }}
                  pointerEvents={!secondStep ? "none" : "auto"}
                />
              )}
            </View>
          </View>
        )}
        {fourthStep && (
          <View
            style={[
              styles.content,
              styles.paymentRow,
              {
                gap: 20,
                opacity: !fourthStep ? 0.5 : 1,
              },
            ]}
            pointerEvents={!fourthStep ? "none" : "auto"}
          >
            <View style={{ flex: 2 }}>
              <Title level={3} style={[styles.title, { flex: 0 }]}>
                <Trans>In this booking</Trans>
              </Title>

              <View>
                <Title level={4} style={[styles.title, { flex: 0 }]}>
                  <Trans>Pricing</Trans> {spot.name}
                </Title>
                {selectedMethod === "direct" && (
                  <View style={styles.item}>
                    <ListItem title={t("Hourly rate and daily price")} />
                    <View style={{ flexDirection: "column" }}>
                      <View style={styles.paymentRow}>
                        <Text>{`${t("Minimum price")}`}</Text>
                        <Text
                          style={{ fontWeight: "bold" }}
                        >{`${displayMinumumRates}`}</Text>
                      </View>
                      <View style={styles.paymentRow}>
                        <Text>{`${t("Price per hour")}`}</Text>
                        <Text
                          style={{ fontWeight: "bold" }}
                        >{`${displayHourlyRates}`}</Text>
                      </View>

                      {hourly.daily && (
                        <View style={styles.paymentRow}>
                          <Text>{`${t("Price per 24 hours")}`}</Text>
                          <Text
                            style={{ fontWeight: "bold" }}
                          >{`${displayDailyRates}`}</Text>
                        </View>
                      )}
                    </View>
                  </View>
                )}

                {selectedMethod === "booking" && (
                  <>
                    <View style={styles.divider} />

                    <View style={[styles.item]}>
                      <ListItem title={t("Booking costs")} />
                      <View>
                        <View style={styles.paymentRow}>
                          <Text>{`${t("Duration")}`}</Text>
                          <Text
                            style={styles.bold}
                          >{`${parkingDuration}`}</Text>
                        </View>
                        <View style={styles.paymentRow}>
                          <Text>{`${t("Total costs")}`}</Text>
                          <Text style={styles.bold}>{`${currencyToDisplayString(
                            cost?.currency || "eur",
                            cost?.amount || 0
                          )}`}</Text>
                        </View>
                      </View>
                    </View>
                  </>
                )}
              </View>
            </View>

            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={[styles.button]}
                onPress={create}
                disabled={!licensePlateValid || loading || isClosed}
              >
                <View
                  style={[
                    styles.buttonInner,
                    selectedMethod === "direct" && { flex: 1 },
                  ]}
                >
                  <View style={[styles.centerAlign]}>
                    <View style={styles.buttonInner}>
                      {loading ? (
                        <ActivityIndicator color={Colors.forest} />
                      ) : (
                        <Icon
                          name={"playCircle"}
                          color={Colors.forest}
                          size={24}
                          iconStyle={{ marginRight: 0 }}
                        />
                      )}
                      <Text level={3}>
                        {selectedMethod === "direct"
                          ? t("Start parking")
                          : t("Confirm booking")}
                      </Text>
                    </View>
                    {selectedMethod === "booking" && (
                      <Text level={4}>
                        {t("(to be paid after parking your vehicle)")}
                      </Text>
                    )}
                  </View>
                </View>
              </TouchableOpacity>

              {selectedMethod === "booking" && (
                <View style={styles.bookingWarning}>
                  <View style={styles.bookingWarningContent}>
                    <Icon name="clock" size={40} color={Colors.mediumOrange} />
                    <Title level={3}>
                      <Trans>Pay for your booking</Trans>
                    </Title>
                  </View>
                  <Text>
                    {t(
                      "Make sure to pre-pay for the booking at the tablet at the reception or via the My Way app to activate it."
                    )}
                  </Text>
                </View>
              )}
            </View>
          </View>
        )}
      </View>
    </Page>
  );
};

export default withAuthentication(observer(ScannedStart));

const styles = StyleSheet.create({
  content: {
    backgroundColor: "white",
    padding: 20,
    marginBottom: 30,
    borderRadius: 20,
  },
  title: {
    flex: 1,
    textAlign: "left",
    marginBottom: Margins.small,
  },
  item: {
    marginVertical: Margins.small,
  },
  row: {
    flexDirection: "row",
    gap: Margins.small,
  },
  vehicleFound: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 40,
  },
  updateButtons: {
    alignSelf: "flex-start",
    padding: 10,
    borderColor: Colors.black,
    borderRadius: 8,
    borderWidth: 1,
  },
  updateButtonsContainer: {
    paddingVertical: 16,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  paymentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bookingWarning: {
    padding: 10,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderRadius: Radius.regular,
    borderColor: Colors.mediumOrange,
  },
  bookingWarningContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 12,
  },
  bold: {
    fontWeight: "bold",
  },
  welcomeContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedMethodContent: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    marginBottom: Margins.small,
  },
  selectedMethod: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 20,
    padding: 10,
  },
  selectedMethodButton: {
    flex: 1,
    padding: 20,
    borderRadius: 5,
    shadowRadius: 10,
    shadowColor: Colors.black,
    justifyContent: "center",
    shadowOpacity: 0.15,
  },
  selectedMethodButtonContent: {
    alignItems: "center",
    flexDirection: "row",
    gap: 24,
    marginBottom: Margins.regular,
  },
  timeInput: {
    height: 50,
    width: 150,
    fontSize: 25,
    backgroundColor: Colors.cream,
    color: Colors.forest,
  },
  divider: {
    flex: 1,
    borderWidth: 0.5,
    height: 1,
    width: "100%",
    borderColor: Colors.lightGrey,
  },
  buttonContainer: { flex: 2, alignItems: "center", gap: 14 },
  button: {
    backgroundColor: Colors.sky,
    flex: 1,
    padding: 20,
    borderRadius: Radius.small,
    alignSelf: "stretch",
  },
  buttonInner: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
  centerAlign: { alignItems: "center" },
});
