import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  TextInput,
  Platform,
  TouchableOpacity,
  View,
} from "react-native";

import { Props as TextInputProps } from "./text";
import { Fonts, Colors, Radius } from "../../constant";
import { Icon } from "../icon";
import { TextStyles } from "../typography/text";

const styles = StyleSheet.create({
  input: {
    backgroundColor: Colors.lightGrey,
    height: 30,
    width: 60,
    borderRadius: Radius.small,
    fontFamily: Fonts.value,
    fontSize: TextStyles[4].fontSize,
    ...(Platform.OS === "web" ? { outlineWidth: 0 } : undefined),
    textAlign: "center",
  },
  inputSelected: {
    borderColor: Colors.darkGreen,
    borderWidth: 1,
    backgroundColor: Colors.white,
  },
});

export interface Time {
  hours: number;
  minutes: number;
}

export interface Props extends Omit<TextInputProps, "onChange" | "value"> {
  value?: Time;
  onChange?: (value?: Time) => void;
  incrementalInput?: boolean;
}

const timeToText = (time?: Time) => {
  return `${`${time?.hours || 0}`.padStart(2, "0")}:${`${
    time?.minutes || 0
  }`.padStart(2, "0")}`;
};

const textToTime = (text: string) => {
  const [hours, minutes] = text.split(":");
  return {
    hours: (parseInt(hours, 10) || 0) % 24,
    minutes: (parseInt(minutes, 10) || 0) % 60,
  } as Time;
};

const TimeInput = (props: Props) => {
  const { style, value, onChange, incrementalInput, ...otherProps } = props;
  const [text, setText] = useState(timeToText(value));
  const [hasFocus, setHasFocus] = useState(false);

  const focus = () => {
    setText("");
    setHasFocus(true);
  };
  const dismiss = () => {
    const time = text ? textToTime(text) : value;
    setText(timeToText(time));
    setHasFocus(false);
    onChange && onChange(time);
  };

  const update = (current: string) => {
    const raw = current.replace(":", "");
    if (raw.length <= 2) {
      setText(raw);
    } else if (raw.length <= 4) {
      const newText = [raw.slice(0, -2), ":", raw.slice(-2)].join("");
      const time = textToTime(newText);
      onChange && onChange(time);
      setText(newText);
    }
  };

  const updateHour = (increment: boolean) => {
    const newText = text;
    const time = textToTime(newText);
    const newTime = {
      hours: (time.hours + (increment ? 1 : -1) + 24) % 24, // Ensure it stays within 0-23
      minutes: time.minutes,
    };
    onChange && onChange(newTime);
    setText(timeToText(newTime));
  };

  const inputStyle = hasFocus && styles.inputSelected;
  return (
    <View style={incrementalInput && { flexDirection: "row", gap: 8 }}>
      {incrementalInput && (
        <TouchableOpacity
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 5,
            borderRadius: 5,
            shadowRadius: 10,
            borderWidth: 0.5,
            borderColor: Colors.lightGrey,
            shadowColor: Colors.black,
            shadowOpacity: 0.15,
          }}
          onPress={() => updateHour(false)}
        >
          <Icon name="subtract" size="large" />
        </TouchableOpacity>
      )}
      <TextInput
        {...otherProps}
        style={[styles.input, inputStyle, style]}
        value={text}
        keyboardType="number-pad"
        textAlign="center"
        onChangeText={update}
        onFocus={focus}
        onBlur={dismiss}
      />
      {incrementalInput && (
        <TouchableOpacity
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 5,
            borderRadius: 5,
            shadowRadius: 10,
            borderWidth: 0.5,
            borderColor: Colors.lightGrey,
            shadowColor: Colors.black,
            shadowOpacity: 0.15,
          }}
          onPress={() => updateHour(true)}
        >
          <Icon name="add" size="large" />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default TimeInput;
