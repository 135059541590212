import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Platform,
  Pressable,
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
} from "react-native";

import InputView, { Props as InputProps } from "./input";
import { Colors, Margins, Radius } from "../../constant";
import { Button } from "../button";
import CountryList, { Country, Search as CountrySearch } from "../country/list";
import { Icon } from "../icon";
import { PopUp } from "../modal";
import { TextStyles } from "../typography/text";
import { LicensePlateCountry } from "../vehicle";
import { Countries } from "../vehicle/styles";

const styles = StyleSheet.create({
  country: {
    height: 50 - 2 * Margins.tiny,
    borderTopStartRadius: Radius.small,
    borderBottomStartRadius: Radius.small,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  hugeCountry: {
    flexBasis: "20%",
    flex: 1,
    borderTopStartRadius: 4,
    borderBottomStartRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: Colors.blue,
  },
  countryText: {
    color: Colors.white,
  },
  down: {
    marginLeft: 4,
    marginRight: -4,
  },
  input: {
    height: 50 - 2 * Margins.tiny,
    marginVertical: 0,
    borderTopRightRadius: Radius.small,
    borderBottomRightRadius: Radius.small,
    fontSize: TextStyles[4].fontSize,
    flexBasis: "auto",
    textAlign: "center",
    overflow: "hidden",
    borderLeftWidth: 0,
  },
  hugeInput: {
    aspectRatio: 1,
    fontSize: 38,
    fontWeight: "700",
    borderWidth: 0.01,
    borderColor: Colors.grey,
    textAlign: "center",
  },
  vehicle: {
    flex: 1,
    height: 50,
    justifyContent: "center",
    marginLeft: Margins.small,
  },
  picker: {
    maxHeight: 600,
    minWidth: 400,
  },
  popUp: {
    flexDirection: "column",
    margin: 16,
    alignSelf: "center",
    justifyContent: "space-between",
    alignContent: "center",
  },
  popUpView: {
    width: 290,
    paddingHorizontal: 16,
    alignSelf: "center",
  },
  popUpButton: { padding: 16, width: 290 },
});

export interface Vehicle {
  country?: string;
  code?: string;
  vehicle?: string;
}

export interface Props
  extends Omit<
      TextInputProps,
      "style" | "value" | "defaultValue" | "onChange" | "editable"
    >,
    Omit<InputProps, "renderContent"> {
  value?: Vehicle;
  defaultValue?: Vehicle;
  onChange?: (value: Vehicle) => void;
  language?: string;
  search?: CountrySearch;
  huge?: boolean;
  noInput?: boolean;
}

const InputLicensePlate = (props: Props) => {
  const {
    label,
    borderColor,
    state,
    addon,
    style,
    contentStyle,
    disabled,
    value,
    defaultValue,
    onChange,
    language,
    search,
    huge,
    noInput,
    ...otherProps
  } = props;
  const [licensePlate, setLicensePlate] = useState<Vehicle>(
    value || defaultValue || { country: "NL", code: "      " }
  );
  const { country } = licensePlate;
  const { t } = useTranslation();

  const [showPicker, setShowPicker] = useState(false);
  const [showLicensePlateInput, SetShowLicensePlateInput] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);

  const maxInputSize = containerWidth * 0.2;
  const calculatedInputSize =
    containerWidth / (licensePlate.code?.replace(/-/g, "").length ?? 1);
  const inputSize = Math.min(calculatedInputSize, maxInputSize);
  const iconSize = inputSize * 0.2;

  const handleResize = useCallback(() => {
    if (props.noInput) {
      setContainerWidth(200 * 0.65);
      return;
    }

    if (typeof window !== "undefined") {
      setContainerWidth(
        window.innerWidth <= 768 ? window.innerWidth * 0.65 : 768 * 0.65
      );
    }
  }, []);

  useEffect(() => {
    handleResize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [handleResize]);

  useEffect(() => {
    if (
      value?.code === licensePlate.code &&
      value?.country === licensePlate.country
    )
      return;
    value && updateCode(value?.code ?? "      ", value?.country);
  }, [value]);

  const updateCode = (code: string, country?: string) => {
    const newLicensePlate = {
      ...licensePlate,
      code: code.toUpperCase(),
      country: country?.toUpperCase() ?? licensePlate.country,
    };
    setLicensePlate(newLicensePlate);
    onChange && onChange(newLicensePlate);
  };

  const updateCountry = (data: Country) => {
    const newLicensePlate = { ...licensePlate, country: data.id };
    setLicensePlate(newLicensePlate);
    onChange && onChange(newLicensePlate);
    setShowPicker(false);
  };

  const renderCountry = (huge: boolean = false) => {
    return (
      <Pressable onPress={() => setShowPicker(true)} disabled={disabled}>
        <LicensePlateCountry
          noInput={props.noInput}
          country={country}
          huge={huge}
          style={
            huge
              ? [{ width: inputSize, height: inputSize }, styles.hugeCountry]
              : styles.country
          }
        >
          {!disabled && !huge && (
            <Icon style={styles.down} name="down" color="white" size={14} />
          )}
          {huge && !props.noInput && (
            <Icon
              name="penBoxIcon"
              color="red"
              size={iconSize}
              style={{
                position: "absolute",
                top: iconSize * 0.5,
                right: iconSize * 0.5,
              }}
            />
          )}
        </LicensePlateCountry>
      </Pressable>
    );
  };

  const renderPicker = () => {
    const picker = (
      <CountryList
        language={language}
        region="Europe"
        preferred={[
          "NL",
          "BE",
          "DE",
          "GB",
          "FR",
          "LU",
          "IT",
          "AT",
          "PL",
          "PT",
          "ES",
        ]}
        onSelect={updateCountry}
        search={search}
      />
    );
    if (Platform.OS === "web")
      return <View style={styles.picker}>{picker}</View>;
    return picker;
  };
  const updateCharacter = (index: number, newChar: string) => {
    const codeExists = licensePlate.code && licensePlate.code.length > 0;
    const code = codeExists ? licensePlate.code : "      ";
    if (index < 0 || index >= code!.length) return;
    const chars = Array.from(code!.replace(/-/g, ""));

    chars[index] = newChar === "" ? " " : newChar;

    updateCode(chars.join(""));
  };

  const CharacterInput = ({
    char,
    index,
    updateCharacter,
    isLastElement,
  }: {
    char: string;
    index: number;
    updateCharacter: (index: number, newChar: string) => void;
    isLastElement: boolean;
  }) => {
    return (
      <View>
        <TextInput
          key={index}
          style={[
            {
              width: inputSize,
              backgroundColor:
                licensePlate.country === "NL" ? Colors.yellow : Colors.white,
              borderTopEndRadius: isLastElement ? 4 : 0,
              color:
                licensePlate.country === "BE" ? Colors.deepRed : Colors.forest,

              borderBottomEndRadius: isLastElement ? 4 : 0,
            },
            styles.hugeInput,
            props.noInput && { fontSize: 22 },
          ]}
          value={char}
          onFocus={() => {
            if ((licensePlate.code?.length ?? 0) < 1) {
              SetShowLicensePlateInput(true);
            }
          }}
          selectTextOnFocus
          onChangeText={(newChar) => {
            updateCharacter(index, newChar);
          }}
          keyboardType={Platform.OS === "ios" ? "default" : "visible-password"}
          maxLength={1}
        />

        {isLastElement && !props.noInput && (
          <Icon
            name="penBoxIcon"
            color="red"
            size={iconSize}
            style={{
              zIndex: 10,
              position: "absolute",
              top: iconSize * 0.5,
              right: iconSize * 0.5,
            }}
          />
        )}
      </View>
    );
  };

  const LicensePlateInput = (licenseRef: string | undefined) => {
    const emptyLicenseInput = licenseRef === "" || licenseRef === undefined;
    const chars = emptyLicenseInput
      ? Array(6).fill("")
      : (licenseRef.replace(/-/g, "") || "").split("");

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "stretch",
        }}
      >
        {renderCountry(huge)}

        {chars.map((char: string, index: number) =>
          char === "-" ? null : (
            <CharacterInput
              key={index}
              char={char}
              index={index}
              updateCharacter={updateCharacter}
              isLastElement={index === chars.length - 1}
            />
          )
        )}
      </View>
    );
  };
  return (
    <>
      {huge ? (
        <InputView renderContent={() => LicensePlateInput(licensePlate.code)} />
      ) : (
        <InputView
          addon={{ before: renderCountry() }}
          renderContent={(contentProps) => {
            const { style, textStyle } = contentProps;
            return (
              <TextInput
                style={[
                  style,
                  textStyle,
                  styles.input,
                  Countries.default.code?.containerStyle,
                  Countries.default.code?.textStyle,
                  Countries.default.licensePlate?.containerStyle,
                ]}
                value={licensePlate.code}
                onChangeText={updateCode}
                keyboardType={
                  Platform.OS === "ios" ? "default" : "visible-password"
                }
                editable={!disabled}
                {...otherProps}
              />
            );
          }}
        />
      )}
      {showPicker && (
        <PopUp top onRequestClose={() => setShowPicker(false)}>
          {renderPicker()}
        </PopUp>
      )}
      {showLicensePlateInput && (
        <PopUp top onRequestClose={() => SetShowLicensePlateInput(false)}>
          <View style={styles.popUp}>
            <InputView
              style={styles.popUpView}
              addon={{ before: renderCountry() }}
              renderContent={(contentProps) => {
                const { style, textStyle } = contentProps;
                return (
                  <TextInput
                    style={[
                      style,
                      textStyle,
                      styles.input,
                      Countries.default.code?.containerStyle,
                      Countries.default.code?.textStyle,
                      Countries.default.licensePlate?.containerStyle,
                    ]}
                    maxLength={10}
                    autoFocus
                    value={licensePlate.code}
                    onChangeText={updateCode}
                    keyboardType={
                      Platform.OS === "ios" ? "default" : "visible-password"
                    }
                    {...otherProps}
                  />
                );
              }}
            />
            <Button
              style={styles.popUpButton}
              title={t("Submit")}
              onPress={() => {
                SetShowLicensePlateInput(false);
              }}
            />
          </View>
        </PopUp>
      )}
    </>
  );
};

export default InputLicensePlate;
