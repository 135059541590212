const Colors = {
  // black
  white: "#FFFFFF",
  veryLightGrey: "#f9f9f9",
  lightGrey: "#e5e8ec",
  grey: "#979797",
  darkGrey: "#404040",
  darkerGrey: "#222222",
  black: "#000000",

  // red
  red: "#F05366",
  lightRed: "#fe5861",
  darkRed: "#fa5a83",
  deepRed: "#B50000",
  // blue
  blue: "#36c5fa",
  lightBlue: "#4bdbff", // Gradient Start
  darkBlue: "#43B9F2", // Gradient End

  // green
  green: "#49dfae",
  darkGreen: "#47e8d4",
  lightGreen: "#5df3ad",

  // purple
  lightPurple: "#a358c2",
  darkPurple: "#5c55cd",

  //orange
  orange: "#faaa81",
  lightOrange: "#fb940a",
  mediumOrange: "#ec622b",
  darkOrange: "#EE8804",

  // Yellow
  yellow: "#f9c21d",

  lime: "#99fe45",
  asparagus: "#68994B",
  forest: "#1b3e3a",
  cream: "#f5f3e2",
  internationalOrange: "#ff5500",
  sky: "#81f8ff",
  blossom: "#ff83ff",

  // UI tints
  moonMist: "#DCDACB",
  alabaster: "#FAFAFA",
  ash: "#C6C4B6",
  gableGreen: "#15312E",
  ebonyClay: "#333333",
};

export default Colors;
