import {
  format,
  startOfWeek,
  addDays,
  lastDayOfWeek,
  addWeeks,
  subWeeks,
  startOfDay,
  isBefore,
  isSameDay,
} from "date-fns";
import { de, enGB, fr, nl } from "date-fns/locale";
import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";

import { Colors } from "../../../storybook";
import { Icon } from "../icon";
import { Text } from "../typography";

const Calendar = ({
  onChange,
  language,
}: {
  onChange: (value: any) => void;
  language?: string;
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const dataForLanguage = (lang: string) => {
    switch (lang.toLowerCase()) {
      case "nl":
        return nl;
      case "fr":
        return fr;
      case "de":
        return de;
      default:
        return enGB;
    }
  };

  const capitalizeFirstLetter = (str: string) => {
    return str
      .split(" ") // Split by space into an array of words
      .map(
        (word: string) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ) // Capitalize the first letter of each word
      .join(" "); // Join them back into a string
  };

  const changeWeekHandle = (btnType: string) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  const onDateClickHandle = (day: React.SetStateAction<Date>) => {
    setSelectedDate(day);
    onChange(day);
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    const today = startOfDay(new Date()); // Get today's date without time
    const dayFormat = "EEE";
    const startDateDay = startOfWeek(currentMonth, { weekStartsOn: 1 });

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const isSelected = selectedDate && isSameDay(cloneDay, selectedDate); // Check if selected
        const isPastDate = isBefore(cloneDay, today); // Check if it's a past date

        days.push(
          <TouchableOpacity
            style={{
              flex: 1,
              width: 80,
              alignItems: "center",
              gap: 4,
              backgroundColor: isSelected
                ? Colors.forest
                : isPastDate
                ? "#d3d3d3"
                : Colors.cream, // Highlight selected date
              opacity: isPastDate ? 0.5 : 1, // Reduce opacity for past dates
            }}
            onPress={() => {
              if (!isPastDate) onDateClickHandle(cloneDay); // Prevent clicks on past dates
            }}
            disabled={isPastDate} // Disable press events
            key={i}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: "#ecefed",
                width: "100%",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Text style={isSelected && { fontWeight: "bold" }}>
                {capitalizeFirstLetter(
                  format(addDays(startDateDay, i), dayFormat, {
                    locale: dataForLanguage(language ?? "en-GB"),
                  })
                )}
              </Text>
            </View>
            <View style={{ padding: 5, alignItems: "center" }}>
              <Text
                level={2}
                style={[
                  { fontWeight: "bold" },
                  isSelected && { color: Colors.lime },
                ]}
              >
                {formattedDate}
              </Text>
              <Text
                style={[
                  { fontWeight: "bold" },
                  isSelected && { color: Colors.lime },
                ]}
              >
                {capitalizeFirstLetter(
                  format(addDays(startDateDay, i), "MMM", {
                    locale: dataForLanguage(language ?? "en-GB"),
                  })
                )}
              </Text>
            </View>
          </TouchableOpacity>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            borderRadius: 10,
            overflow: "hidden",
            shadowRadius: 25,
            shadowColor: Colors.black,
            shadowOpacity: 0.25,
          }}
          key={day.toString()} // Ensure unique key for each row
        >
          {days}
        </View>
      );
      days = [];
    }
    return (
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          style={{
            padding: 5,
            borderRadius: 5,
            shadowRadius: 10,
            borderWidth: 0.5,
            borderColor: Colors.lightGrey,
            shadowColor: Colors.black,
            shadowOpacity: 0.15,
          }}
          onPress={() => changeWeekHandle("prev")}
        >
          <Icon name="left" size="large" />
        </TouchableOpacity>
        {rows}
        <TouchableOpacity
          style={{
            padding: 5,
            borderRadius: 5,
            shadowRadius: 10,
            borderWidth: 0.5,
            borderColor: Colors.lightGrey,
            shadowColor: Colors.black,
            shadowOpacity: 0.15,
          }}
          onPress={() => changeWeekHandle("next")}
        >
          <Icon name="right" size="large" />
        </TouchableOpacity>
      </View>
    );
  };
  return <View>{renderCells()}</View>;
};

export default Calendar;
