import { UTMParams } from "./../../../routes/booking";
import Constants from "expo-constants";
import { Platform } from "react-native";

import { BookingCost } from ".";
import Booking from "./booking";
import { PackageType, Spot } from "..";
import Store from "../..";
import { callCloudFunction, asyncAction, Analytics } from "../../../helpers";
import Collection from "../../general/collection";
import PaymentOption from "../payment/option";

interface CreateData {
  spot: Spot;
  startAt?: Date;
  endAt?: Date;
  vehicleIds: string[];
  paymentOption?: PaymentOption;
  cost?: BookingCost;
  anonymous?: boolean;
  selectedAccessPackage?: PackageType;
  utmParams?: UTMParams;
  kiosk?: boolean;
}

interface ResponseData {
  booking?: Booking;
  groupId?: string;
  redirect?: string;
}

class Bookings extends Collection<Booking> {
  readonly userId?: string;
  constructor(where: Record<string, string>, store: Store) {
    super(
      "bookings",
      {
        createDocument: (source, options) =>
          new Booking(source, options, store),
        query: (ref) => {
          let query = ref
            // @ts-ignore
            .where("anonymous", "==", true)
            .where("closed", "==", false);
          Object.keys(where).forEach((key) => {
            query = query.where(key, "==", where[key] || "");
          });
          return query;
        },
      },
      store
    );
    this.userId = this.store.auth.user?.id;
  }

  request = asyncAction<CreateData, ResponseData>(async (data) => {
    const {
      spot,
      startAt,
      endAt,
      vehicleIds,
      paymentOption,
      cost,
      anonymous = false,
      selectedAccessPackage,
      utmParams,
      kiosk = false
    } = data;
    const stripe = await this.store.payment.getStripe();

    // Filter out empty UTM parameters
    const filteredUtmParams = utmParams
      ? Object.entries(utmParams)
          .filter(([_, value]) => value != null)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      : {};

    // Build base function arguments
    const funcArgs: any = {
      spotId: spot.id,
      vehicleIds,
      appVersion: `1.0.0 (web)`,
      platform: "web",
      device: Constants.platform?.web?.ua || "unknown",
      anonymous,
      ...(Object.keys(filteredUtmParams).length > 0 && {
        utmParams: filteredUtmParams,
      }),
      ...(cost && { ...cost }),
    };

    let funcName = "requestDirectBookingEU";
    let callbackUrl;

    // Handle future bookings
    if (startAt && endAt) {
      funcArgs.startAt = startAt.toISOString();
      funcArgs.endAt = endAt.toISOString();

      if (vehicleIds.length > 1) {
        funcName = "requestGroupBookingEU";
        if (selectedAccessPackage) {
          funcArgs.selectedAccessPackage = selectedAccessPackage;
        }
        if (Platform.OS === "web") {
          callbackUrl = `${window.location.origin}/group/:groupId`;
        }
      } else {
        funcName = "requestFutureBookingEU";
        if (selectedAccessPackage) {
          const accessPackage = spot.singleAccessPackageByType(
            selectedAccessPackage
          );
          funcArgs.selectedAccessPackage = selectedAccessPackage;
          funcArgs.cost = {
            amount: accessPackage?.rate.value,
            currency: accessPackage?.rate.currency,
          } as BookingCost;
        }
        funcArgs.kiosk = kiosk;
        if (Platform.OS === "web") {
          callbackUrl = `${window.location.origin}/booking/:bookingId`;
        }
      }

      // Add iDEAL payment details if applicable
      if (paymentOption?.ideal) {
        funcArgs.ideal = {
          bankName: paymentOption.ideal.bankName,
          cardName: paymentOption.ideal.cardName,
          callbackUrl,
        };
      }
    } else if (endAt) {
      funcArgs.endAt = endAt.toISOString();
    }
    // Create booking
    const { booking, group, paymentIntent, setupIntent, fullPaymentIntent } =
      await callCloudFunction(funcName, funcArgs);

    // Handle payment flow
    let redirect = fullPaymentIntent;

    // Handle no payment intent for future bookings & group bookings
    // Direct bookings on web can be paid later
    if (
      (!paymentIntent && !kiosk) &&
      !setupIntent &&
      startAt &&
      endAt &&
      (booking.cost?.amount ?? 0) !== 0
    ) {
      throw new Error("Payment not defined");
    }

    if (paymentIntent || setupIntent) {
      const options = {
        payment_method: paymentOption!.paymentMethodId,
        return_url: group
          ? `${window.location.origin}/group/${group.id}`
          : `${window.location.origin}/booking/${booking.id}`,
      };

      if (paymentIntent) {
        if (paymentOption!.type === "ideal") {
          await stripe?.confirmIdealPayment(
            paymentIntent.clientSecret,
            options
          );
        } else if (paymentOption!.type === "card") {
          await stripe?.confirmCardPayment(paymentIntent.clientSecret, options);
        }

        Analytics.logEvent("purchase", {
          affiliation: "vicky-app",
          coupon: booking.creditUsed,
          currency: booking.cost?.currency || "EUR",
          items: [spot.analyticsData],
          transaction_id: booking.id,
          tax: 0.21 * (booking.cost?.amount || 0),
          value: booking.cost?.amount || 0,
          ...(Object.keys(filteredUtmParams).length > 0 && filteredUtmParams),
        });
      } else if (setupIntent) {
        if (paymentOption!.type === "ideal") {
          await stripe?.confirmIdealSetup(setupIntent.clientSecret, options);
        } else if (paymentOption!.type === "card") {
          await stripe?.confirmCardSetup(setupIntent.clientSecret, options);
        }
      }
    }

    if (group) {
      return { groupId: group.id, redirect };
    }

    return {
      booking: new Booking(`bookings/${booking?.id}`, {}, this.store),
      redirect,
    };
  });
}

export default Bookings;
